var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    _c("div", { staticClass: "section-content" }, [
      _c(
        "div",
        { staticClass: "list-container" },
        [
          _c(
            "b-card",
            { staticClass: "card-text shadow-sm p-3 mb-5 bg-white rounded" },
            [
              _c("b-card-title", { staticClass: "card-title" }, [
                _vm._v(" Requisitos do Sistema "),
              ]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("b-card-text", [
                        _c(
                          "strong",
                          { staticStyle: { "font-family": "Poppins" } },
                          [_vm._v("Sistema Operacional")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("b-card-text", { staticClass: "card-text-2" }, [
                        _vm._v(" Windows 7 ou Superior "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("b-card-text", [
                        _c(
                          "strong",
                          { staticStyle: { "font-family": "Poppins" } },
                          [_vm._v("Arquitetura")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("b-card-text", { staticClass: "card-text-2" }, [
                        _vm._v(" 64-bits "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "button-download",
                  attrs: { href: _vm.url, variant: "primary" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../assets/icons/download-app.svg"),
                      width: "30",
                    },
                  }),
                  _vm._v(" Retextoar Cliente Versão " + _vm._s(_vm.appVersion)),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Download")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }